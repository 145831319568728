const initTextAnimSlider = () => {
  const textAnimHolder = document.querySelector('[data-words]');
  const textAnimItem = document.querySelectorAll('.text-anim-item');
  const textAnimItems = document.querySelector('.text-anim-items');
  const animIn = 'anim-in';
  const animOut = 'anim-out';
  let animNextItem = null;
  let animPrevItem = null;
  let animFirstLoad = false;
  const animDuration = textAnimHolder.getAttribute('data-delay');
  let animCounter = 0;
  let setTimeAnim;
  let setTimeAnimResize;

  const animFunc = () => {
    clearTimeout(setTimeAnim);

    setTimeAnim = setTimeout(() => {
      animFirstLoad = true;

      if (animPrevItem !== null) {
        animPrevItem.classList.add(animOut);
      }
      animNextItem = textAnimItems.children[animCounter];
      animNextItem.classList.remove(animOut);
      animNextItem.classList.add(animIn);

      animPrevItem = animNextItem;

      if (animCounter === textAnimItem.length - 1) {
        animCounter = 0;
      } else {
        animCounter += 1;
      }
      animFunc();
    }, animFirstLoad ? animDuration : 100);
  };

  setTimeout(() => {
    animFunc();
  }, 100);

  function resizeHandler() {
    clearTimeout(setTimeAnim);
    clearTimeout(setTimeAnimResize);

    setTimeAnimResize = setTimeout(() => {
      animFunc();
    }, 50);
  }

  window.addEventListener('resize', resizeHandler);
  window.addEventListener('orientationchange', resizeHandler);
};

document.addEventListener('DOMContentLoaded', initTextAnimSlider);
