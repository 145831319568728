// Owl Carousel behaviour
/* global $ */

window.addEventListener('load', () => {
  const quotes = $('.carousel-quotes');
  const avatar = $('.carousel-quotes-avatar');
  quotes.on('initialize.owl.carousel', () => {
    let item = quotes.find('.quote-item').eq(0).find('.quote-item-avatar img').clone();
    avatar.find('img').replaceWith(item);
  });

  quotes.owlCarousel({
    smartSpeed: 1000,
    autoplay: true,
    nav: false,
    dots: true,
    items: 1,
    margin: 20,
  }).on('changed.owl.carousel', event => {
    let index  = event.page.index;
    let item = quotes.find('.quote-item').eq(index).find('.quote-item-avatar img').clone();
    avatar.find('img').replaceWith(item);
  });

  if( $(window).width() > 991){
    $('.jc-work-item').remove();
  }

  $('.carousel-images-modal').owlCarousel({
    smartSpeed: 1000,
    navText: [
      '<span></span>',
      '<span></span>',
    ],
    items: 1,
    loop: true,
    responsive: {
      0: {
        dots: false,
        nav: true,
        autoHeight: false,
      },
      991: {
        dots: true,
        nav: true,
      },
    },
  });

  $('.carousel-cards').owlCarousel({
    smartSpeed: 1000,
    nav: true,
    navText: [
      '<span></span>',
      '<span></span>',
    ],
    responsive: {
      0: {
        dots: true,
        nav: false,
        items: 1,
        stagePadding: 30,
        center: true,
      },
      991: {
        dots: false,
        nav: true,
        items: 3,
      },
    },
  });

  // $('.carousel-works').owlCarousel({
  //   smartSpeed: 300,
  //   fluidSpeed: 300,
  //   navText: [
  //     '<span></span>',
  //     '<span></span>',
  //   ],
  //   margin: 20,
  //   center: true,
  //   stagePadding: 20,
  //   responsive: {
  //     0: {
  //       dots: true,
  //       nav: false,
  //       items: 1,
  //     },
  //     991: {
  //       dots: false,
  //       nav: true,
  //     },
  //   },
  //   // onDrag: (event) => {
  //   //   console.log(event);
  //   // },
  // });

  $('.carousel-works').slick({
    dots: true,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '35px',
  });

  $('.carousel-processes').owlCarousel({
    smartSpeed: 300,
    fluidSpeed: 300,
    navText: [
      '<span></span>',
      '<span></span>',
    ],
    margin: 10,
    responsive: {
      0: {
        dots: true,
        nav: false,
        items: 1,
      },
      991: {
        dots: false,
        nav: true,
        items: 3,
        slideBy: 3,
      },
    },
    // onDrag: (event) => {
    //   console.log(event);
    // },
  });

  const owlStage = document.querySelector('.carousel-processes .owl-stage');

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'attributes') {
        const styles = mutation.target.getAttribute('style').split(';');
        const position = styles[0].match(/\((-?\d*px),/);
        if (position) {
          $('.carousel-processes').attr('style', `background-position-x: ${position[1]}`);
        }
      }
    });
  });

  observer.observe(owlStage, {
    attributes: true,
  });
});
