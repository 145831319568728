const inputList = document.querySelectorAll('.input-field');

inputList.forEach((elem) => {
  const label = elem.nextElementSibling;

  elem.addEventListener('keydown', () => {
    if (elem.classList.contains('validate')) {
      if (elem.checkValidity()) {
        elem.classList.add('valid');
        label.classList.add('valid');
        elem.classList.remove('invalid');
        label.classList.remove('invalid');
      } else {
        elem.classList.add('invalid');
        label.classList.add('invalid');
        elem.classList.remove('valid');
        label.classList.remove('valid');
      }

      if (elem.value === '') {
        elem.classList.remove('valid');
        label.classList.remove('valid');
        elem.classList.remove('invalid');
        label.classList.remove('invalid');
      }
    }
  });

  elem.addEventListener('focus', () => {
    elem.classList.add('active');
    label.classList.add('active');

    if (elem.classList.contains('validate')) {
      if (elem.value !== null) {
        if (elem.checkValidity()) {
          elem.classList.add('valid');
          label.classList.add('valid');
        } else {
          elem.classList.remove('valid');
          elem.classList.add('invalid');
        }
      }

      if (elem.value === '') {
        elem.classList.remove('valid');
        elem.classList.remove('invalid');
      }
    }
  });

  elem.addEventListener('blur', () => {
    if (elem.value === '') {
      label.classList.remove('active');
      label.classList.remove('valid');
      label.classList.remove('invalid');
    }
    if (elem.classList.contains('validate')) {
      if (elem.value !== null) {
        elem.classList.remove('valid');
        elem.classList.remove('invalid');
      }
    }
  });
});
