/* eslint-disable no-unused-vars */
/* global $, bootstrap, YT, AOS */

import './carousels';
import './modal';
import './rotator';
import './textfield';

window.addEventListener('load', () => {
  AOS.init({
    easing: 'ease-in-out',
    //mirror: true,
    duration: 1000,
  });

  // Activate popovers
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]:not(.figure)'));
  popoverTriggerList.map((popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl));

  // Activate Tooltips
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]:not(.js-region)'));
  tooltipTriggerList.map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));

  // Create the observer
  const options = { threshold: 0.005 };
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (entry.target.dataset.animateClass) {
          entry.target.classList.add(entry.target.dataset.animateClass);
        }
      }
    });
  }, options);

  // Tell the observer which elements to track
  document.querySelectorAll('.animate__animated').forEach((o) => {
    observer.observe(o);
  });

  // Select

  document.querySelector('.select-wrapper').addEventListener('click', (evt) => {
    const target = evt.currentTarget;
    target.querySelector('.select').classList.toggle('open');
  });

  // eslint-disable-next-line no-restricted-syntax
  for (const option of document.querySelectorAll('.custom-option')) {
    option.addEventListener('click', (evt) => {
      const target = evt.currentTarget;
      if (!target.classList.contains('selected')) {
        const selected = target.parentNode.querySelector('.custom-option.selected');
        if (selected) {
          selected.classList.remove('selected');
        }
        target.classList.add('selected');
        target.closest('.select').querySelector('.select__trigger span').textContent = target.textContent;
      }
    });
  }

  window.addEventListener('click', (evt) => {
    const select = document.querySelector('.select');
    if (!select.contains(evt.target)) {
      select.classList.remove('open');
    }
  });


});
